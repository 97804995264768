// @flow
import React, { useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import ApplySection from '../../../components/Merchant/ApplySection';
import Hero from '../../../components/Merchant/shared/Hero';
import bg from '../../../images/materials.svg';
import ContactForm from '../../../components/Merchant/ContactForm/ContactForm';
import FormSubmitted from '../../../components/Merchant/ContactForm/FormSubmitted';

const WhitePaperPage = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  return (
    <Layout isMerchant>
      <Hero title="お役立ち情報" bg={bg} />
      <section>
        {isFormSubmitted ? (
          <FormSubmitted />
        ) : (
          <ContactForm
            description="必要事項をご記入いただくと、後払い決済のノウハウやECサイトの運営に役立つ情報を無料でダウンロードできます。導入前の情報収集にご活用ください。"
            button="ダウンロード"
            setIsFormSubmitted={setIsFormSubmitted}
            page="merchant"
          />
        )}
      </section>
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default WhitePaperPage;
